import edu from 'url:./assets/graduation-cap.svg'
import know from 'url:./assets/lightbulb.svg'
import lang from 'url:./assets/comment.svg'
import pdf from 'url:./assets/jonathan-ginn-2019.pdf'
import work from 'url:./assets/briefcase.svg'

/**
 * Main application component.
 *
 * @function       App
 * @returns  {JSX}
 */
export default function App () {
  return (
    <div class='lg:container mx-auto py-12 px-6 relative'>
      <div class='absolute top-3 left-8 text-sm'>
        <a href={pdf} class='px-2'>print</a>
        / <a href='2009/' class='px-2'>2009</a>
        / <a href='2015/' class='px-2'>2015</a>
      </div>

      <div class='ui.square top-2 left-2 bg-blue' />

      <div class='ui.square top-2 right-2 bg-black' />

      <div class='ui.square bottom-2 left-2 bg-black' />

      <div class='ui.square bottom-2 right-2 border-2 border-black' />

      <div class='grid grid-cols-2 gap-x-12'>
        <div>
          <h1 class='grid' style='grid-template-columns: auto min-content auto;'>
            <div>Designer</div>
            <div>/</div>
            <div>Developer</div>
          </h1>

          <p>My mantra is <strong>Simple Solid Statement</strong>: to
            convey a strong and clear message through well crafted design.
          </p>

          <p>I have worked as a freelancer for over 10 years in web development and
            graphic design. I strive to learn the best technologies in this vast and
            ever changing domain. I aim to create stunning, reusable, and highly
            efficient projects that comply with today’s design and programming
            standards.
          </p>

          <p>Leading projects is a growing aspect of my career. Inspiring visions,
            providing guidance, and supporting development are all welcomed tasks. I
            aspire to raise projects and coworkers to new heights with my technical
            expertise.
          </p>

          <h2>Bilingual <img src={lang} alt='' class='-mt-1' /></h2>

          <dl>
            <dt>English</dt>
            <dd>Native - Written & Spoken</dd>

            <dt>French</dt>
            <dd>Native - Written & Spoken</dd>
          </dl>

          <h2>Education <img src={edu} alt='' class='-ml-3' /></h2>

          <dl>
            <dt>Interactive Multimedia Developer</dt>
            <dd>Algonquin College - Ottawa ON, 2007-2009</dd>

            <dt>Diploma, Secondary school Grande-Rivière</dt>
            <dd>Gatineau QC, 2005</dd>
          </dl>

          <h2>Knowledge <img src={know} alt='' class='-ml-2 mt-5' /></h2>

          <div class='grid grid-cols-2'>
            <h3>Linux / DevOps</h3>

            <div class='ui.dots'>
              <div class='ui.dot' />
              <div class='ui.dot' />
              <div class='ui.dot' />
              <div class='ui.dot ui.black' />
              <div class='ui.dot ui.black' />
            </div>

            <h3>PHP / SQL</h3>

            <div class='ui.dots'>
              <div class='ui.dot' />
              <div class='ui.dot' />
              <div class='ui.dot' />
              <div class='ui.dot' />
              <div class='ui.dot' />
            </div>

            <h3>JavaScript / ES6</h3>

            <div class='ui.dots'>
              <div class='ui.dot' />
              <div class='ui.dot' />
              <div class='ui.dot' />
              <div class='ui.dot' />
              <div class='ui.dot' />
            </div>

            <h3>CSS / SASS</h3>

            <div class='ui.dots'>
              <div class='ui.dot' />
              <div class='ui.dot' />
              <div class='ui.dot' />
              <div class='ui.dot' />
              <div class='ui.dot' />
            </div>

            <h3>UI / UX</h3>

            <div class='ui.dots'>
              <div class='ui.dot' />
              <div class='ui.dot' />
              <div class='ui.dot' />
              <div class='ui.dot' />
              <div class='ui.dot ui.black' />
            </div>

            <h3>Design / Branding</h3>

            <div class='ui.dots'>
              <div class='ui.dot' />
              <div class='ui.dot' />
              <div class='ui.dot' />
              <div class='ui.dot' />
              <div class='ui.dot' />
            </div>
          </div>
        </div>

        <div>
          <div class='text-right -mt-8 pr-2'>
            <div class='border-0 border-b-2 border-black w-3/4 float-right text-lg font-bold italic -mt-2'>Jonathan Ginn</div>

            <div class='clear-right'>jonathan@ginn.work</div>

            <div>+1 (613) 618–5669</div>
          </div>

          <h2>Work History <img src={work} alt='' class='-ml-3 mt-8' /></h2>

          <h3>IT / Web Developer for Gilmore Global</h3>

          <p>Forefront programmer for a new initiative to bring a B2B Java
            application to the public as an eCommerce website: a Laravel 5 / PHP 7
            application consuming JSONAPI / REST APIs provided by the core Java
            project.
          </p>

          <p>Established lead role on multiple projects through expertise and
            promoting individual growth in team members. Tasked to oversee, review,
            and guide peer development.
          </p>

          <p>Developed MVVC SPA applications powered by Vue.js / JavaScript ES6
            running on Laravel Lumen API servers. Handled DevOps for development and
            release CentOS machines hosting internal and external facing
            applications.
          </p>

          <h4>Responsibilities</h4>

          <ul>
            <li>Review and merge code using Git and GitLab</li>
            <li>Define and execute tasks for sprints within Redmine</li>
            <li>Develop and maintain PHP / JS codebase</li>
            <li>Deploy and manage CentOS web servers</li>
            <li>Assist and consult for various internal projects</li>
          </ul>

          <h3 class='ui.dotted'>Designer & Developer for MarinerTek</h3>

          <p>Crafted strong brands, engaging marketing material, and modern web
            designs using Photoshop, Illustrator, and In-Design.
          </p>

          <p>Extended company services to include web development featuring
            WordPress CMS, Magento eCommerce, and Yii.
          </p>

          <h3 class='ui.dotted'>Consultant & Developer for Olivus Inc.</h3>

          <p>Developer on Freecourse.ca - free online Pleasure Craft Operator Card
            study guide and testing compliant with Transport Canada regulations.
          </p>

          <p>Developer on Continuing Care Reporting System eLearning SPA made
            portable with ActiveXObject for the Canadian Institute for Health
            Information.
          </p>

          <h1>Contact</h1>

          <dl>
            <dt>Jonathan Ginn</dt>
            <dd>jonathan@ginn.work</dd>

            <dt>Website</dt>
            <dd>https://ginn.work/</dd>

            <dt>Phone</dt>
            <dd>+1 ( 613 ) 618 - 5669</dd>
          </dl>
        </div>
      </div>
    </div>
  )
}
